import * as React from "react"
import { motion } from "framer-motion"
import { addPropertyControls, ControlType } from "framer"

export function TextClip(props) {
    const { text, lineCount, textStyle, letterMode, maxCharacters } = props

    let lineClampStyle = {}
    if (letterMode) {
        lineClampStyle = {
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: `calc(${maxCharacters} * 0.6ch + 1.5ch)`, // Adjusted width calculation
        }
    } else {
        lineClampStyle = {
            display: "-webkit-box",
            WebkitLineClamp: lineCount,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: textStyle.textAlign,
        }
    }

    const transformStyle = {
        textTransform: textStyle.textTransform,
    }

    const containerStyle = {
        display: "flex",
        justifyContent: getJustifyContent(textStyle.textAlign),
        alignItems: getAlignItems(textStyle.textAlign),
        overflow: "visible",
    }

    function getJustifyContent(textAlign) {
        switch (textAlign) {
            case "center":
                return "center"
            case "right":
                return "flex-end"
            case "left":
            default:
                return "flex-start"
        }
    }

    function getAlignItems(textAlign) {
        switch (textAlign) {
            case "center":
                return "center"
            case "right":
            case "left":
            default:
                return "flex-start"
        }
    }

    return (
        <motion.div style={{ ...containerStyle }}>
            <motion.div
                style={{
                    ...lineClampStyle,
                    ...textStyle,
                    ...transformStyle,
                    fontFamily: textStyle.font,
                }}
            >
                {text}
            </motion.div>
        </motion.div>
    )
}

addPropertyControls(TextClip, {
    text: {
        type: ControlType.String,
        defaultValue:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    letterMode: {
        type: ControlType.Boolean,
        title: "Letter Mode",
        defaultValue: false,
    },
    maxCharacters: {
        type: ControlType.Number,
        title: "Max Characters",
        defaultValue: 20,
        min: 1,
        step: 1,
        displayStepper: true,
        hidden(props) {
            return !props.letterMode
        },
    },
    lineCount: {
        type: ControlType.Number,
        defaultValue: 1,
        min: 1,
        displayStepper: true,
        step: 1,
        hidden(props) {
            return props.letterMode
        },
    },
    textStyle: {
        type: ControlType.Object,
        controls: {
            font: {
                type: ControlType.String,
                defaultValue: "Inter",
            },
            color: { type: ControlType.Color, defaultValue: "#000" },
            fontSize: { type: ControlType.Number, defaultValue: 16 },
            fontWeight: {
                type: ControlType.Number,
                defaultValue: 400,
                displayStepper: true,
                step: 100,
            },
            lineHeight: {
                type: ControlType.Number,
                defaultValue: 1.5,
                displayStepper: true,
                step: 0.1,
            },
            letterSpacing: {
                type: ControlType.Number,
                defaultValue: 0,
                displayStepper: true,
                step: 0.1,
            },
            textAlign: {
                type: ControlType.Enum,
                options: ["left", "center", "right"],
                defaultValue: "left",
            },
            textTransform: {
                type: ControlType.Enum,
                options: ["none", "capitalize", "lowercase", "uppercase"],
                defaultValue: "none",
                title: "Transform",
            },
        },
    },
})

export default TextClip
